import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31')
];

export const server_loads = [0];

export const dictionary = {
		"/(landing)": [30,[4]],
		"/(app)/dash": [5,[2]],
		"/(app)/error": [6,[2]],
		"/(test)/group": [31],
		"/(app)/parallax": [7,[2]],
		"/(app)/privacy": [8,[2]],
		"/(app)/profile": [~9,[2]],
		"/(app)/query": [~10,[2]],
		"/(app)/terms": [11,[2]],
		"/(app)/todo": [12,[2,3]],
		"/(app)/todo/3d": [13,[2,3]],
		"/(app)/todo/card3d": [14,[2,3]],
		"/(app)/todo/cards/[set]": [~15,[2,3]],
		"/(app)/todo/chat": [16,[2,3]],
		"/(app)/todo/drag": [17,[2,3]],
		"/(app)/todo/editor/[room]": [~18,[2,3]],
		"/(app)/todo/flow": [19,[2,3]],
		"/(app)/todo/form": [20,[2,3]],
		"/(app)/todo/multipart-upload": [21,[2,3]],
		"/(app)/todo/toast": [22,[2,3]],
		"/(app)/todo/transition": [23,[2,3]],
		"/(app)/todo/video": [24,[2,3]],
		"/(app)/todo/webtransport": [25,[2,3]],
		"/(app)/todo/worker-rayon": [27,[2,3]],
		"/(app)/todo/worker-wgpu": [28,[2,3]],
		"/(app)/todo/worker": [~26,[2,3]],
		"/(app)/todo/ws": [29,[2,3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';